import React from "react"

import Layout from "../components/layout"
import PageBanner from "../components/pageBanner"
import ContactMap from "../components/contactMap"
import SEO from "../components/seo"
import contact from "../support/contact"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <PageBanner title="Contact Us" />
    <ContactMap />
    <ContactMap
      address="Level 11, 456 Lonsdale Street, Melbourne Victoria 3000"
      lat={-37.8129561}
      lng={144.9564782}
    />
    <ContactMap
      address="Level 13, 111 Elizabeth Street, Sydney New South Wales 2000"
      lat={-33.8697548}
      lng={151.2078674}
    />
    <ContactMap
      address="483 Green Lanes, London N134BS United Kingdom"
      lat={51.6226545}
      lng={-0.1074885}
      phoneNumberDisplay={contact.phoneNumber}
    />
  </Layout>
)

export default ContactPage
