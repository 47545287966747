import React, { FC } from "react"
import GoogleMapReact from "google-map-react"
import contact from "../support/contact"
import { Link } from "gatsby"

const GOOGLE_MAP_KEY = "AIzaSyD2H6murxAXupo3wheE8wYT0pCicgongvY"
const DEFAULT_ZOOM = 13
const DEFAULT_LAT = contact.coordinates.lat
const DEFAULT_LNG = contact.coordinates.lng

const MAP_STYLE = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#6590b2",
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
]

interface Props {
  zoom?: number
  lat?: number
  lng?: number
  address?: string
  email?: string
  phoneNumber?: string
  phoneNumberDisplay?: string
}

const ContactMap: FC<Props> = ({
  zoom = DEFAULT_ZOOM,
  lat = DEFAULT_LAT,
  lng = DEFAULT_LNG,
  address = contact.address,
  email = contact.email,
  phoneNumber = contact.phoneNumber,
  phoneNumberDisplay = contact.phoneNumberDisplay,
}) => {
  return (
    <>
      <div className="page-cda contact-details-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="all-contact-details">
                <div className="row">
                  <div className="col-lg-4 col-sm-6 col-12 d-flex">
                    <div className="single-contact-details">
                      <div className="icon">
                        <span className="flaticon-placeholder"></span>
                      </div>
                      <h4 className="title">Our Address</h4>
                      {address.split("\n").map((line, i) => (
                        <p className="desc" key={i}>
                          {line}
                        </p>
                      ))}
                      <a
                        className="link"
                        href={`https://www.google.com/maps/@${lat},${lng},12.71z`}
                        target="_blank"
                        rel="nofollow"
                      >
                        Directions
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-6 col-12 d-flex">
                    <div className="single-contact-details">
                      <div className="icon">
                        <span className="flaticon-message"></span>
                      </div>
                      <h4 className="title">Email Address</h4>
                      <a className="desc" href={`mailto:${email}`}>
                        {email}
                      </a>
                      <a className="link" href={`mailto:${email}`}>
                        Email us
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-sm-12 col-12 d-flex">
                    <div className="single-contact-details">
                      <div className="icon">
                        <span className="fa fa-phone"></span>
                      </div>
                      <h4 className="title">Phone</h4>
                      <a className="desc" href={`tel:${phoneNumber}`}>
                        {phoneNumberDisplay}
                      </a>
                      <a className="link" href={`tel:${phoneNumber}`}>
                        Call us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="map-area">
        <div className="googleMap">
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
            defaultCenter={{ lat, lng }}
            defaultZoom={zoom}
            options={{ styles: MAP_STYLE }}
            yesIWantToUseGoogleMapApiInternals
          />
        </div>
      </div>
    </>
  )
}

export default ContactMap
